<template>
    <v-container>
        <div class="ma-5">
            <h3 class="text-center">ー アルバム ー</h3>
        </div>
        <div v-if="drawings.length">
            <v-row v-for="(drawing, index) in drawings.slice().reverse()" :key="index" class="mb-4 justify-center">
                <v-card class="d-flex justify-space-between align-center flex-column mt-1" outlined
                    style="border-color: rgba(173, 216, 230, 1); border-width: 2px;">
                    <v-card-text class="d-flex justify-space-between align-center pa-1">
                        <div class="image-container">
                            <img :src="drawing.image" alt="User Image">
                            <!-- <div class="penguin-name">{{ drawing.penguin_name }}</div> -->
                        </div>
                        <div v-if="getPicture(drawing.penguin_name)" class="json-image-container pa-0 ma-0">
                            <img :src="getPicture(drawing.penguin_name)" alt="Matching Image">
                        </div>
                    </v-card-text>
                    <v-card-text class="timestamp-text pa-0">
                        {{ drawing.penguin_name }}<span class="timestamp">{{ drawing.timestamp }}</span>
                    </v-card-text>
                </v-card>
            </v-row>
        </div>
        <div v-else>
            <p>まだデータがありません。おえかきしてみよう！</p>
        </div>
    </v-container>
</template>






<script>
export default {
    props: ['aquarium'], // ルーターから受け取るプロップス
    data() {
        return {
            drawings: [], // 取得した画像とペンギン名を保存する配列
            json: [],
        };
    },
    async created() {
        await this.fetchJson(this.aquarium);
        this.fetchDrawings();
    },
    mounted() {
        this.$returnScroll();
    },
    methods: {
        fetchDrawings() {
            this.$axios
                .post('https://nakagawa.nkmr.io/api/pensaku/get_drawing.php', { uid: this.$store.state.uid })
                .then((response) => {
                    if (response.data.success) {
                        this.drawings = response.data.drawings;
                    } else {
                        console.error(response.data.message);
                    }
                })
                .catch((error) => {
                    console.error("Failed to fetch images:", error);
                });
        },
        async fetchJson(fileName) {
            try {
                const response = await this.$axios.get(`https://nakagawa.nkmr.io/api/response_json.php?file=${fileName}`);
                this.json = response.data;
                // console.log(response.data)
            } catch (error) {
                console.error('データの取得に失敗しました:', error);
                if (error.response) {
                    console.error('レスポンスエラー:', error.response);
                } else if (error.request) {
                    console.error('リクエストエラー:', error.request);
                } else {
                    console.error('その他のエラー:', error.message);
                }
            }
        },
        getPicture(penguinName) {
            const matchingEntry = this.json.find(item => item.name === penguinName);
            if (matchingEntry) {
                // console.log(`Found picture for ${penguinName}:`, matchingEntry.picture);
            } else {
                console.log(`No picture found for ${penguinName}`);
            }
            return matchingEntry ? matchingEntry.picture : null;

        },
    }
};
</script>

<style scoped>
.image-container {
    width: 150px;
    /* 固定幅を設定 */
    height: 150px;
    /* 固定高さを設定 */
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-image: url('https://nakagawa.nkmr.io/img/penguin_backimg1.png');
    background-size: cover;
    background-position: center;
    border-radius: 5px;
}

.json-image-container {
    width: 120px;
    /* 固定幅を設定 */
    height: 150px;
    /* 固定高さを設定 */
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-container img,
.json-image-container img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.penguin-name {
    position: absolute;
    top: 5px;
    left: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 2px 5px;
    border-radius: 3px;
    font-size: 12px;
}

.v-card {
    max-width: 600px;
    margin: auto;
    /* カードを中央揃えにする */
    text-align: center;
    /* 中央揃え */
}

.timestamp-text {
    width: 100%;
    text-align: center;
    margin-top: 10px;
}

.timestamp {
    color: gray;
    font-size: 14px;
    display: block;
}
</style>